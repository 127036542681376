<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Общество с ограниченной ответственностью
        «Техавтотест»</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">

            <table class="info-page">
              <tr>
                <td>Генеральный директор</td>
                <td>Буравенко Павел Николаевич
                </td>
              </tr>
              <tr>
                <td>Номер операторов ТО в реестре РСА</td>
                <td>08753 от 04 октября 2017</td>
              </tr>
              <tr>
                <td>ИНН</td>
                <td>7702420400</td>
              </tr>
              <tr>
                <td>КПП</td>
                <td>770201001</td>
              </tr>
              <tr>
                <td>ОГРН</td>
                <td>1177746695246</td>
              </tr>
              <tr>
                <td>Юридический адрес</td>
                <td>129090, г. Москва, Астраханский переулок, д. 5, стр. 3, этаж 1, помещение 2, комната 1.
                </td>
              </tr>
              <tr>
                <td>Фактический адрес</td>
                <td>143900, Московская область, г.Балашиха, Западная промзона, шоссе Энтузиастов, д. 2</td>
              </tr>
              <tr>
                <td>Телефон</td>
                <td>+7(495) 367-11-73
                </td>
              </tr>
              <tr>
                <td>Электронная почта</td>
                <td>info@.tehavtotest.ru</td>
              </tr>
              <tr>
                <td>Сайт</td>
                <td>www.tehavtotest.ru</td>
              </tr>
              <tr>
                <td>ОКПО</td>
                <td>16482461</td>
              </tr>
              <tr>
                <td>ОКАТО</td>
                <td>45286570000</td>
              </tr>
              <tr>
                <td>ОКТМО</td>
                <td>45379000000</td>
              </tr>
              <tr>
                <td>ОКОГУ</td>
                <td>4210014</td>
              </tr>
              <tr>
                <td>ОКФС</td>
                <td>16</td>
              </tr>
              <tr>
                <td>ОКОПФ</td>
                <td>12300</td>
              </tr>
              <tr>
                <td>р/с</td>
                <td>407 028 103 0000 026 4471</td>
              </tr>
              <tr>
                <td>к/с</td>
                <td>301 018 104 0000 0000 555</td>
              </tr>
              <tr>
                <td>Банк</td>
                <td>ПАО «Промсвязьбанк» г.Москвы</td>
              </tr>
              <tr>
                <td>БИК</td>
                <td>044525555</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>
.info-page{
  font-size: 120%;
  width: 100%;
}
.info-page tr{
  border-bottom: #5e5e5e 1px solid;
  height: 40px;
}
</style>